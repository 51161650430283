<template>
	<b-modal id="activate-transportation" :title="modalTitle" ok-title="Save" ref="modal" @cancel="handleCancel"
		@ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this transportation with plate no <b>"{{ plateNo }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { TransportationUtil } from '@/utils/transportationUtil';

// DAO
import dispatchDAO from '@/database/dispatches';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'activate-transportation',
	components: { Loading },
	data() {
		return {
			selTransportation: {},
			isActive: true,
			
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		plateNo() {
			return this.selTransportation.plateNo;
		},
		modalTitle() {
			return this.isActive === 'true' ? 'Deactivate Transportation' : 'Activate Transportation';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelTransportation', (transportation) => {
			this.selTransportation = transportation;
			this.isActive = transportation.isActive;
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selTransportation.isActive = this.isActive;
			this.$store.commit('SET_CURR_TRANSPORTATION', this.selTransportation);
		},
		handleCancel() {
			this.resetStatus();
		},

		async validateActivation() {
			let isValid = true;

			const dispatches = await dispatchDAO.fetchDispatchesByPlateNo(this.plateNo);
			let filteredDispatches = _.filter(dispatches, (o) => {
				return o.status === 'Draft' || o.status == 'In-Transit';
			});

			if (!_.isEmpty(filteredDispatches)) {
				this.$toaster.warning('Deactivation unsuccessful! Transportation is currently in operation.');
				isValid = false;
			}

			return isValid;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (await this.validateActivation()) {
				await this.handleSubmit();
			}
		},

		getTransportationObj(param) {
			// timestamp
			param.dateUpdated = DateUtil.getCurrentTimestamp();
			param.updatedBy =  this.loggedUser.id;

			return TransportationUtil.cleanupFields(param);
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				// update the status
				this.selTransportation.isActive = this.toggleStatus(this.isActive);

				let result = await this.$store.dispatch(
					'updateTransportation',
					this.getTransportationObj(this.selTransportation)
				);

				if (result.isSuccess) {
					let successMsg = '';
					if (result.data.isActive === 'true') {
						successMsg = `Transportation "${this.plateNo}" is now activated!`;
					} else {
						successMsg = `Transportation "${this.plateNo}" is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseSaveTransportation', result.data);
					this.$refs.modal.hide();

				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during transportation activation of "${this.plateNo}"`;
					} else {
						errorMsg = `There's a problem encountered during transportation deactivation of "${this.plateNo}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during transportation activation of "${this.plateNo}"`;
				} else {
					errorMsg = `There's a problem encountered during transportation deactivation of "${this.plateNo}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelTransportation');
	},
};
</script>
