<template>
	<b-modal id="add-transportation" title="Add Transportation" ref="modal" ok-title="Add" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Company">
							<v-select name="Company" class="style-chooser" label="text" placeholder=" - Please select - " :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>

					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Plate No./Conduction No." label-for="plateNo" description>
							<b-form-input id="plateNo" name="plate no" type="text" class="numFont"
								v-model="form.plateNo" v-validate="{
									required: true,
									regex: plateNoRegex,
								}" maxlength="12" placeholder="e.g. AAA-1111/A1-A111" />
							<span v-show="errors.has('plate no')" class="help-block">{{
								errors.first('plate no')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group v-show="hasTransportPermission" label-for="hasCheckingInterval">
					<b-form-checkbox id="hasCheckingInterval" name="hasCheckingInterval"
						v-model="form.hasCheckingInterval">
						Checking Interval (hrs):
					</b-form-checkbox>
				</b-form-group>

				<b-form-group v-show="hasTransportPermission" label-for="checkingInterval">
					<b-form-input id="checkingInterval" name="Checking Interval" type="number" class="numFont"
						v-model="form.checkingInterval"
						v-validate="`${form.hasCheckingInterval ? 'required|min_value:1|max_value:24' : ''}`" min="1"
						max="24" step="1" onwheel="this.blur()" placeholder="Checking Interval"
						:readonly="!form.hasCheckingInterval" />
					<span v-show="errors.has('Checking Interval')" class="help-block">{{
						errors.first('Checking Interval')
					}}</span>
				</b-form-group>

				<b-row class="my-12">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Description" label-for="description" description>
							<b-form-textarea name="Description" type="text" v-model="form.description" maxlength="200"
								:rows="3" placeholder="Description" v-validate="{
									required: true,
									regex: remarksRegex
								}" />
							<span v-show="errors.has('Description')" class="help-block">{{
								errors.first('Description')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { TransportationUtil } from '@/utils/transportationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'add-transportation',
	components: {
		Loading,
	},
	props: {
		companyFilter: {
			type: Object,
			required: true,
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		allTransportationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: { ...TransportationUtil.getDefaultTransportationObj() },
			selCompany: { ...config.companyDefaultValue },

			hasTransportPermission: this.$store.getters.hasTransportPermission,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		plateNo() {
			return this.form.plateNo;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		plateNoRegex() {
			return config.plateNoRegex;
		},
	},
	watch: {
		'form.checkingInterval': function () {
			this.form.checkingInterval = parseInt(this.form.checkingInterval);
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.plateNo = ValidationUtil.removeExcessWhiteSpace(
				this.form.plateNo
			);
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);

			if (this.form.plateNo.length > 0 && ValidationUtil.objectHasField(
				'plateNo',
				this.form.plateNo,
				this.allTransportationsObj
			)) {
				this.$toaster.warning('Transportation with plate no. ' + this.form.plateNo + ' already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// assign the selected company to the user
			this.updateCompanySelection();

			try {
				let result = await this.$store.dispatch('addTransportation', this.form);

				if (result.isSuccess) {
					this.$toaster.success(`New Transportation "${this.plateNo}" was created successfully.`);
					EventBus.$emit('onCloseSaveTransportation', result.data);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error creating transportation "${this.plateNo}"". Please try again.`);
				}

			} catch (error) {
				this.$toaster.error(`Error creating transportation "${this.plateNo}"". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateCompanySelection() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
		},
		onReset() {
			/* Reset our form values */
			this.form = { ...TransportationUtil.getDefaultTransportationObj() };

			// init company
			if (this.companyFilter.id) {
				this.selCompany = this.companyFilter;
			} else {
				this.selCompany = { ...config.companyDefaultValue };
			}

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>