<template>
    <div>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Primary Information" active>
                    <b-card-text>
                        <b-form-group label="Company" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ row.item.company }}
                        </b-form-group>

                        <b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="truncate-text">
                                <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
                                    type="html" :text="row.item.description" />
                            </span>
                        </b-form-group>

                        <b-form-group label="Plate No./Conduction No." label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span class="numFont">{{ row.item.plateNo }}</span>
                        </b-form-group>

                        <b-form-group v-show="hasTransportPermission" label="Has Checking Interval?" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            {{ row.item.hasCheckingInterval ? 'YES' : 'NO' }}
                        </b-form-group>

                        <b-form-group v-show="hasTransportPermission" label="Checking Interval" label-cols-sm="3"
                            label-class="font-weight-bold pt-0" label-align-sm="right">
                            <span class="numFont">{{ row.item.checkingInterval }}</span>
                        </b-form-group>

                        <b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateCreated) }}
                        </b-form-group>

                        <b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            {{ getFormattedDateWithTime(row.item.dateUpdated) }}
                        </b-form-group>

                        <b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
                            label-align-sm="right">
                            <span v-if="row.item.isActive === 'true'">
                                <b-badge variant="success">Active</b-badge>
                            </span>
                            <span v-else>
                                <b-badge variant="secondary">Inactive</b-badge>
                            </span>
                        </b-form-group>

                    </b-card-text>
                </b-tab>
                <b-tab title="Change Logs" v-if="isAllowedToEdit(row.item)">
                    <TransportationChangeLogsDetailsView :row="row" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
// Component
import TransportationChangeLogsDetailsView from './TransportationChangeLogsDetailsView.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
    name: 'transportation-details-view',
    components: {
        TransportationChangeLogsDetailsView,
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        allCompaniesObj: {
			type: Object,
			required: true,
		},
    },
    data() {
        return {
            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isViewer: this.$store.getters.isViewer,
            hasTransportPermission: this.$store.getters.hasTransportPermission,
        }
    },
    methods: {
        isAllowedToEdit(transportation) {
			return this.isSuperAdmin || (this.hasTransportPermission && !this.isViewer
				&& UserUtil.hasCompanyAccess(this.allCompaniesObj, transportation.companyId));
		},
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
    },
}
</script>