import { db } from '@/config/firebase';
import _ from 'lodash';


async function fetchDispatchesByPlateNo(plateNo) {
    let dispatchesObj = {};

    const dbRef = db.collection('dispatches').where("transportation.plateNo", "==", plateNo);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let dispatchObj = doc.data();
        dispatchesObj[id] = {
            id: id,
            ...dispatchObj
        }
    });

    return dispatchesObj;
}

function generateQuery(filter) {
    let query = db.collection("dispatches");

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dateCreated", ">=", filter.fromTimestamp);
    }
    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dateCreated", "<=", filter.toTimestamp);
    }

    if (filter.receivedStartDate && filter.receivedStartDate > 0) {
        query = query.where("dateReceived", ">=", filter.receivedStartDate);
    }
    if (filter.receivedEndDate && filter.receivedEndDate > 0) {
        query = query.where("dateReceived", "<=", filter.receivedEndDate);
    }

    if (filter.sourceCompany && filter.sourceCompany.id && filter.sourceCompany.id.length > 0) {
        query = query.where("source.companyId", "==", filter.sourceCompany.id);
    } else if (filter.sourceCompany && filter.sourceCompany.ids && !_.isEmpty(filter.sourceCompany.ids)) {
        query = query.where("source.companyId", "in", filter.sourceCompany.ids);
    }

    if (filter.destinationCompany && filter.destinationCompany.id && filter.destinationCompany.id.length > 0) {
        query = query.where("destination.companyId", "==", filter.destinationCompany.id);
    } else if (filter.destinationCompany && filter.destinationCompany.ids && !_.isEmpty(filter.destinationCompany.ids)) {
        query = query.where("destination.companyId", "in", filter.destinationCompany.ids);
    }

    if (filter.accountNo && filter.accountNo.id && filter.accountNo.id.length > 0) {
        query = query.where("clientAccountId", "==", filter.accountNo.id);
    }

    if (filter.plateNo && filter.plateNo.length > 0) {
        query = query.where("transportation.plateNo", "==", filter.plateNo);
    }

    if (filter.status && filter.status.length > 0) {
        query = query.where("status", "==", filter.status);
    }

    if (filter.assetType && filter.assetType.id && filter.assetType.id.length > 0) {
        query = query.where("assets.assetTypeId", "==", filter.assetType.id);
    }

    if (filter.truckCompany && filter.truckCompany.id && filter.truckCompany.id.length > 0) {
        query = query.where("transportation.companyId", "==", filter.truckCompany.id);
    }

    if (filter.dispatchId && filter.dispatchId.length > 0) {
        query = query.where("dispatchId", "==", filter.dispatchId);
    }

    if (filter.dispatchNo && filter.dispatchNo.length > 0) {
        query = query.where("dispatchNo", "==", filter.dispatchNo);
    }

    if (filter.status === 'Received') {
        query = query.orderBy("dateReceived", "asc");
    }
    return query;
}

function getDispatchListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function getDispatches(filter) {
    let dispatchesObj = {};

    let query = generateQuery(filter);
    const querySnapshots = await query.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let dispatchObj = doc.data();
        dispatchesObj[id] = {
            id: id,
            ...dispatchObj
        }
    });

    return dispatchesObj;
}

async function getInTransitDispatchesFromSource(companyId, storageLocationId) {
    let dispatchesObj = {};

    let query = db.collection('dispatches')
        .where('status', '==', 'In-Transit');
    if (companyId) {
        query = query.where('source.companyId', '==', companyId);
    }
    if (storageLocationId) {
        query = query.where('source.storageLocationId', '==', storageLocationId);
    }

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        let dispatchObj = doc.data();
        dispatchObj.id = doc.id;
        dispatchesObj[doc.id] = dispatchObj;
    });
    return dispatchesObj;
}

async function getDispatchByDispatchId(dispatchId) {
    let query = db.collection('dispatches')
        .where('dispatchId', '==', dispatchId)
    const querySnapshot = await query.get();

    let dispatchObj = {};
    querySnapshot.forEach(item => {
        dispatchObj = item.data();
        dispatchObj.id = item.id;
    });

    return dispatchObj;
}

export default {
    fetchDispatchesByPlateNo,
    getDispatchListener,
    getDispatches,
    getInTransitDispatchesFromSource,
    getDispatchByDispatchId
}